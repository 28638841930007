import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, Router } from 'react-router-dom';
import axios from 'axios'

import { configurations } from './config'
import AuthApi from './AuthApi'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import ReactGA from 'react-ga';
const createHistory = require("history").createBrowserHistory

const history = createHistory()
console.log("Initialize Google Analytics")
ReactGA.initialize('UA-199279992-1')
history.listen((location, action) => {
  ReactGA.pageview(location.pathname + location.search)
  console.log("Location:", location.pathname + location.search)
  var username = localStorage.getItem("username")
  console.log(username)
  ReactGA.set({ userId: username })
});

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const Circa = React.lazy(() => import('./containers/Circa/Circa'));
const Assessment = React.lazy(() => import('./containers/Assessment'));
const PublicDashboard = React.lazy(() => import('./views/Pages/PublicDashboard/pages/PublicDashboard'))
// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

const ProtectedRoute = ({ auth, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => auth ? (
        <Component {...props} />
      ) :
        (
          <Redirect to="/login" />
        )
      }
    />
  )
}

const ProtectedLogin = ({ auth, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => !(auth) ? (
        <Component {...props} />
      ) :
        (
          <Redirect to="/home" />
        )
      }
    />
  )
}

const Routes = () => {
  const Auth = React.useContext(AuthApi)
  const loggedIn = () => {
    Auth.setAuth(true);
  }

  const loggedOut = () => {
    Auth.setAuth(false)
  }

  return (
    <React.Suspense fallback={loading()}>
      <Switch>
        <ProtectedLogin exact path="/login" name="Login Page" auth={Auth.auth} component={props => <Login {...props} loggedIn={loggedIn} />} />
        <ProtectedLogin exact path="/register" name="Register Page" auth={Auth.auth} component={props => <Register {...props} pageId={1} loggedIn={loggedIn} />} />
        <Route exact path="/product/:prodUUID" name="QR Page" component={props => <PublicDashboard {...props} />} />
        <ProtectedRoute exact path="/404" name="Page 404" auth={Auth.auth} component={props => <Page404 {...props} loggedOut={loggedOut} />} />
        <ProtectedRoute exact path="/500" name="Page 500" auth={Auth.auth} component={props => <Page500 {...props} loggedOut={loggedOut} />} />
        <ProtectedRoute path="/assessment" name="Assessment Form" auth={Auth.auth} component={props => <Assessment {...props} loggedOut={loggedOut} />} />
        <ProtectedRoute path="/" name="Circa" auth={Auth.auth} component={props => <Circa {...props} loggedOut={loggedOut} />} />
      </Switch>
    </React.Suspense>
  )
}

const App = () => {
  const [auth, setAuth] = useState(false)

  const checkLogin = () => {
    let url = configurations.url
    axios({
      method: "get",
      url: url + "check"
    })
      .then(res => {
        console.log("Response: ", res)
        if (res.data.auth === 1) {
          var username = localStorage.getItem("username")
          console.log(username)
          ReactGA.set({ userId: username });
          // console.log("Initialize Google Analytics")
          // ReactGA.initialize('UA-199279992-1');
          // ReactGA.pageview(window.location.pathname + window.location.search);
          // ReactGA.set({ userId: username });
          setAuth(true)
        }
      })
  }

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
    var username = localStorage.getItem("username")
    console.log(username)
    ReactGA.set({ userId: username });
    console.log("Location:", window.location.pathname + window.location.search)
    checkLogin()
  }, [])

  return (
    <AuthApi.Provider value={{ auth, setAuth }}>
      <Router history={history}>
        <Routes />
      </Router>
    </AuthApi.Provider>
  );
}

export default App;
